import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VAvatar,{attrs:{"size":"40","color":"grey lighten-2 elevation-1"}},[_c('img',{attrs:{"src":'https://cdn.dsmcdn.com/seller-store/uploads/963769/a2475368-06bb-46b0-9d77-35b0130c50fe.jpeg',"alt":"Avatar"}})])],1)]}}])},[_c(VList,[_c(VListItem,[_c(VListItemTitle,{staticClass:"px-1",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setDark.apply(null, arguments)}}},[_c(VSwitch,{attrs:{"dense":"","inset":"","label":"Karanlık Mod"},model:{value:(_vm.darkMode),callback:function ($$v) {_vm.darkMode=$$v},expression:"darkMode"}})],1)],1),_c(VDivider),_c(VListItem,{on:{"click":_vm.signOut}},[_c(VListItemTitle,[_vm._v("Oturumu kapat")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }