<template>
  <div class="main-layout">
    <!-- Navigation -->
    <v-navigation-drawer v-model="drawer" app class="elevation-1">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <div>
              <div class="title font-weight-bold">Kit Panel</div>
              <div class="overline">version 1.0.0</div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list>
        <v-subheader class="mt-1 overline">Menu</v-subheader>
        <v-list-item v-for="item in items" :key="item.text" :to="item.link" :exact="item.exact">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="menu-text">{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar app>
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div>
        <UserMenu></UserMenu>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fill-height fluid>
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import UserMenu from '../components/common/UserMenu'

export default {
  components: {
    UserMenu
  },
  data: () => ({
    drawer: null,
    items: [
      { icon: 'mdi-view-dashboard-outline', text: 'TY Siparişler', link: '/dashboard', exact: true },
      { icon: 'mdi mdi-chart-arc', text: 'TY Raporlar', link: '/ty_reports', exact: true },
      { icon: 'mdi mdi-treasure-chest', text: 'TY Ürün İstatistik', link: '/ty_product_stats', exact: true },
      { icon: 'mdi-plus-box', text: 'TY Ürün Tanımla', link: '/ty_product', exact: true },
      // { icon: 'mdi-account-multiple-outline', text: 'Users', link: '/users' }
    ]
  })
}
</script>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  flex: 1 1 auto;
}

.menu-text {
  font-size: 0.875rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 14px;
}

.theme--dark {
  .v-icon {
    color: #ccced2;
  }

  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #ccced2 !important;
  }
}

.v-list-item--active {
  &,
  .v-icon {
    color: lighten($primary, 25%);
  }

  .menu-text {
    font-weight: bold;
  }
}
</style>
